<template>
  <b-col class="cc-bg-light p-2 mr-2">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <p>Pagamento de Frete</p>
          </b-col>
          <b-col class="d-flex justify-content-end">{{ frete_value | moneyFormat }}</b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <p>Vale Pedágio</p>
          </b-col>
          <b-col class="d-flex justify-content-end">{{ pedagio_value | moneyFormat }}</b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>Vale Abastecimento</p>
          </b-col>
          <b-col class="d-flex justify-content-end">{{ abastecimento_value | moneyFormat }}</b-col>
        </b-row> -->
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <h3>Total solicitado</h3>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <h2>
              {{
                nota_comercial.valor_emissao | moneyFormat
              }}</h2>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <h4>Limite restante</h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <h3>
              {{
                currentCompanyLimit | moneyFormat
              }}</h3>
          </b-col>
        </b-row> -->
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
import {moneyFormat} from "@core/comp-functions/data_visualization/datatable";
import {BCol, BRow} from "bootstrap-vue";

export default {
  name: 'ConcessionSummary',
  props: {
    nota_comercial: {},
    frete_value: {},
    pedagio_value: {},
    abastecimento_value: {}
  },
  components: {
    BRow, BCol
  },
  filters: {moneyFormat},
  computed: {
    currentCompanyLimit() {
      return this.$store.state.auth.userData.empresa.limite
    },
  }
}
</script>
