<template>
  <b-col class="cc-bg-light p-2">
    <b-row>
      <b-col>
        <b-row>
          <b-col><p>Status</p></b-col>
          <b-col class="d-flex justify-content-end">
            <p>
              <b-badge :variant="statusLabel | statusBadgeVariant" pill>{{ statusLabel }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col><p>Taxa - a.m.</p></b-col>
          <b-col class="d-flex justify-content-end">
            <p>
              {{ nota_comercial.taxa_juros | percentageFormatter }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col><p>Taxa de Escrituração</p></b-col>
          <b-col class="d-flex justify-content-end">
            <p>
              {{ nota_comercial.taxa_escrituracao | percentageFormatter }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col><p>Prazo - dias </p></b-col>
          <b-col class="d-flex justify-content-end">
            <p>
              {{ nota_comercial.prazo }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col><p>Data de vencimento</p></b-col>
          <b-col class="d-flex justify-content-end">
            <p>
              {{ nota_comercial.data_vencimento | dateFormatter }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col><p>Data de resgate</p></b-col>
          <b-col class="d-flex justify-content-end">
            <p>
              {{ nota_comercial.data_emissao | dateFormatter }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <p>Valor a pagar</p>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <p>
              {{ nota_comercial.valor_devido | moneyFormat }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
import {
  dateFormatter,
  moneyFormat,
  percentageFormatter,
  statusBadgeVariant
} from "@core/comp-functions/data_visualization/datatable";
import {BCol, BRow, BBadge} from "bootstrap-vue";

export default {
  name: 'ConcessionInfos',
  props: {
    nota_comercial: {},
  },
  data() {
    return {
      statusesNames: {
        aguardando_transferencia: 'Processando', vencida: 'Vencida',
        executada: 'Depositado', liquidada: 'Liquidada',
        aguardando_assinatura: 'Aguardando assinatura', cancelada: 'Cancelada'
      }
    }
  },
  components: {
    BRow, BCol, BBadge
  },
  filters: {moneyFormat, statusBadgeVariant, percentageFormatter, dateFormatter},
  computed: {
  currentCompanyTerm() {
    return this.$store.state.auth.userData.empresa.prazo
  },
  statusLabel() {
    return this.statusesNames[this.nota_comercial.status]
  }
  }
}
</script>
