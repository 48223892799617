<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <AppPageHeader
        subtitle="Visualize aqui o detalhamento da operação selecionada"
        title="Detalhes da operação"
      >
        <template #header-left>
          <div class="d-flex justify-content-end align-items-center">
            <b-button
              variant="outline-primary"
              size="sm"
              @click="$router.push({ name: 'partner-dashboard.index' })"
              >Voltar</b-button
            >
          </div>
        </template>
      </AppPageHeader>
    </b-card>
    <b-card>
      <b-alert
        show
        variant="danger"
        class="p-1 border-danger mb-1"
        v-if="nota_comercial.status === 'cancelada'"
      >
        <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1" />
        Sua Nota Comercial foi cancelada. Entre em contato conosco para saber mais sobre
        este cancelamento ou repita a operação.
      </b-alert>
      <b-row>
        <b-col cols="6">
          <b-card class="cc-bg-light mb-0 h-100">
            <div class="d-flex justify-content-between mb-1">
              <span
                >Status
                <feather-icon
                  icon="InfoIcon"
                  size="14"
                  v-b-tooltip.hover
                  :title="tooltipMessage"
                  stroke="#093272"
              /></span>
              <span class="d-flex justify-content-end">
                <div v-html="comercialNoteStatusBadge(nota_comercial.status)" />
              </span>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1 text-dark">Total a pagar no vencimento</h6>
              <h6 class="mb-1 text-dark">
                {{ moneyFormat(nota_comercial.valor_devido) }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Total solicitado</h6>
              <h6 class="mb-1">{{ moneyFormat(nota_comercial.valor_emissao) }}</h6>
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="cc-bg-light mb-0 h-100">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Prazo - dias</h6>
              <h6 class="mb-1">{{ nota_comercial.prazo }}</h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Taxa de juros</h6>
              <h6 class="mb-1">{{ nota_comercial.taxa_juros }}%</h6>
            </div>
            <div v-if="!taxaEscrituracaoIsNullOrZero" class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Taxa de Escrituração</h6>
              <h6 class="mb-1">
                {{ nota_comercial.taxa_escrituracao }}%
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Data de vencimento</h6>
              <h6 class="mb-1">{{ formatDate(nota_comercial.data_vencimento) }}</h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Data de resgate</h6>
              <h6 class="mb-1">{{ formatDate(nota_comercial.data_emissao) }}</h6>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import AppPageHeader from "@core/components/app-page-header/AppPageHeader";
import { BCard, BCol, BRow, BAlert, BButton, BBadge, VBTooltip } from "bootstrap-vue";
import ConcessionSummary from "@/views/client/concessoes/components/ConcessionSummary";
import ConcessionInfos from "@/views/client/concessoes/components/ConcessionInfos";
import {
  moneyFormat,
  dateFormatter,
  percentageFormatter,
  statusBadgeVariant,
  comercialNoteStatusBadge,
} from "@core/comp-functions/data_visualization/datatable";
import format from "date-fns/format";
import parse from "date-fns/parse";

export default {
  name: "ShowConcession",
  components: {
    ConcessionInfos,
    ConcessionSummary,
    AppPageHeader,
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BBadge,
  },
  filters: {
    moneyFormat,
    dateFormatter,
    percentageFormatter,
    statusBadgeVariant,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.setData();
  },
  data() {
    return {
      nota_comercial: {},
      statusesNames: {
        aguardando_transferencia: "Processando",
        vencida: "Vencida",
        executada: "Depositado",
        liquidada: "Liquidada",
        aguardando_assinatura: "Aguardando assinatura",
        cancelada: "Cancelada",
      },
      tooltipMessage:
        "Após a emissão da Nota Comercial a mesma deverá ser assinada até as 14:00h do mesmo dia. Se não houver a assinatura até este horário limite, a operação será automaticamente cancelada e poderá ser solicitada novamente no dia seguinte.",
    };
  },
  methods: {
    async setData() {
      const id = this.$route.params.id;
      const response = await this.$store.dispatch("concession/partnerFetch", id);
      this.nota_comercial = response.data;
    },
    comercialNoteStatusBadge(status) {
      return comercialNoteStatusBadge(status);
    },
    moneyFormat(value, minimumFractionDigits = 2) {
      return moneyFormat(value, minimumFractionDigits);
    },
    formatDate(value) {
      if (!value) return "";

      let date = parse(value, "yyyy-MM-dd", new Date());

      return format(date, "dd/MM/yyyy");
    },
  },
  computed: {
    statusLabel() {
      return this.statusesNames[this.nota_comercial.status];
    },
    taxaEscrituracaoIsNullOrZero() {
      return this.nota_comercial.taxa_escrituracao === '0.0' || this.nota_comercial.taxa_escrituracao === null
    }
  },
};
</script>
